<template>
  <div class="auth flex flex-col md:flex-row items-start">
    <div class="right_part">
      <h1 class="color_white" style="font-size: 40px;">{{ $t("signUp.bemVindo") }}</h1>
      <p class="color_white mb-4">
        {{ $t("signUp.ja-tem-conta") }}
        <router-link class="text-primary" to="/login"> {{ $t("signUp.entrar") }} </router-link>
      </p>

      <div class="auth-container box edit_box rounded-lg">
        <form role="form" class="login-form">
          <div class="form-group flex flex-col mb-4">
            <span for="name" class="mb-2">{{ $t("signUp.nome") }}</span>
            <input
              @keyup.enter="submit"
              id="name"
              type="text"
              class="form-control"
              v-model="form.first_name"
              :placeholder="$t('signUp.nome')"
            />
            <FieldError :invalidFields="invalidFields" name="first_name" />
          </div>
          <div class="form-group flex flex-col mb-4">
            <span for="last_name" class="mb-2">{{ $t("signUp.sobrenome") }}</span>
            <input
              @keyup.enter="submit"
              id="last_name"
              type="text"
              class="form-control"
              v-model="form.last_name"
              :placeholder="$t('signUp.sobrenome')"
            />
            <FieldError :invalidFields="invalidFields" name="last_name" />
          </div>
          <div class="form-group flex flex-col mb-4">
            <span for="email" class="mb-2">{{ $t("signUp.email") }}</span>
            <input
              @keyup.enter="submit"
              id="email"
              type="text"
              class="form-control"
              v-model="form.email"
              :placeholder="$t('signUp.email')"
            />
            <FieldError :invalidFields="invalidFields" name="email" />
          </div>
          <div class="form-group flex flex-col mb-4">
            <span for="password" class="mb-2">{{ $t("signUp.senha") }}</span>
            <input
              @keyup.enter="submit"
              id="password"
              type="password"
              class="form-control"
              v-model="form.password"
              :placeholder="$t('signUp.senha')"
            />
            <FieldError :invalidFields="invalidFields" name="password" />
            <div class="flex gap-1">
              <div
                class="pass-strength"
                :class="{ active: passwordStrength >= 1 }"
              ></div>
              <div
                class="pass-strength"
                :class="{ active: passwordStrength >= 2 }"
              ></div>
              <div
                class="pass-strength"
                :class="{ active: passwordStrength >= 3 }"
              ></div>
              <div
                class="pass-strength"
                :class="{ active: passwordStrength >= 4 }"
              ></div>
            </div>
          </div>
          <div class="form-group flex flex-col mb-4">
            <span for="password" class="mb-2">{{ $t("signUp.repetir-senha") }}</span>
            <input
              @keyup.enter="submit"
              id="password"
              type="password"
              class="form-control"
              v-model="form.password_confirmation"
              :placeholder="$t('signUp.repetir-senha')"
            />
            <FieldError
              :invalidFields="invalidFields"
              name="password_confirmation"
            />
          </div>
          <div class="form-group flex flex-col">
            <span for="phone" class="mb-2">{{ $t("signUp.cpf") }}</span>
            <input
              @keyup.enter="submit"
              id="cpf"
              type="text"
              class="form-control"
              v-maska="'###.###.###-##'"
              v-model="form.cpf"
              :placeholder="$t('signUp.cpf')"
            />
            <FieldError :invalidFields="invalidFields" name="cpf" />
          </div>
          <div class="form-group flex flex-col">
            <span for="phone" class="mb-2">{{ $t("signUp.celular") }}</span>
            <input
              @keyup.enter="submit"
              id="phone"
              type="text"
              class="form-control"
              v-maska="'(##) #####-####'"
              v-model="form.phone"
              :placeholder="$t('signUp.celular')"
            />
            <FieldError :invalidFields="invalidFields" name="phone" />
          </div>
          <div class="text-center flex flex-col">
            <a
              href="javascript:void(0);"
              @click="submit"
              class="btn btn-grad"
              :disabled="loading"
            >
              <img
                v-if="loading"
                width="25"
                class="block mx-auto"
                :src="require('@/assets/spinner.svg')"
                alt=""
              />
              <span v-else>{{ $t("signUp.cadastrar") }}</span>
            </a>
            <a
              href="/login"
              class="btn button mt-2 small text-center font-black text-primary"
              >{{ $t("signUp.entrar") }}</a
            >
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { openAPI, oauth } from "@/api";
import { mapActions } from "vuex";
import api from "@/api";
import FieldError from "@/components/FieldError";

export default {
  components: { FieldError },
  data() {
    return {
      form: {
        first_name: "",
        last_name: "",
        username: "",
        email: "",
        password: "",
        password_confirmation: "",
        cpf: "",
        phone: "",
      },
      invalidFields: [],
      success: false,
      loading: false,
    };
  },
  computed: {
    passwordStrength() {
      let strength = 0;
      if (this.form.password.length > 6) {
        strength++;
      }
      if (/[A-Z]/.test(this.form.password)) {
        strength++;
      }
      if (/[a-z]/.test(this.form.password)) {
        strength++;
      }
      if (/[^0-9a-zA-Z]/.test(this.form.password)) {
        strength++;
      }

      return strength;
    },
  },
  methods: {
    ...mapActions(["setToken", "setUser"]),
    async submit() {
      if (this.formIsValid()) {
        this.loading = true;
        openAPI.post("register-music", this.form).then(async (data) => {
          if (data.errors) {
            const returnedFields = Object.keys(data.errors);
            returnedFields.map((field) => {
              this.invalidFields.push(`${field}:${data.errors[field][0]}`);
            });
          } else {
            const response = await oauth.post("token", {
              username: this.form.email,
              password: this.form.password,
            });

            if (
              response.token_type &&
              response.access_token &&
              response.token_type == "Bearer"
            ) {
              this.setToken(response.access_token);
              this.user = await api.get("user");
              // if (this.user.two_factor_enable == 0) {
              this.setUser(this.user.data);
              this.$router.push({ name: "Home" });
              // } else {
              //   this.show = 'authentication'
              // }
            }
          }
          this.loading = false;
        });
      }
    },
    formIsValid() {
      return true;
      // let valid = true;
      // this.invalidFields = [];

      // Object.keys(this.form).map((field) => {
      //   if (this.form[field] === "") {
      //     this.invalidFields = [
      //       ...this.invalidFields,
      //       `${field}:Este campo não pode ser vazio.`,
      //     ];
      //     valid = false;
      //   }
      // });
      // this.invalidFields = [];
      // return valid;
    },
  },
};
</script>

<style scoped>
.auth {
  background-image: url("../../assets/11.jpg");
  min-height: calc(100vh - 75px);
  background-position: -1px -1px;
}

.right_part {
  padding: 20px;
  width: 40%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

@media screen and (min-width: 1024px) {
  .right_part {
    padding: 60px;
  }
}

@media screen and (max-width: 768px) {
  .right_part {
    width: 100%;
  }
}
.pass-strength {
  margin-top: 8px;
  height: 8px;
  border-radius: 8px;
  background-color: rgba(255, 255, 255, 0.3);
  flex: 1;
}

.pass-strength.active:first-child {
  background: #f50000;
}
.pass-strength.active:nth-child(2) {
  background: #f59e00;
}
.pass-strength.active:nth-child(3) {
  background: #cff500;
}
.pass-strength.active:nth-child(4) {
  background: rgb(0, 255, 0);
}
</style>
