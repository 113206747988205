<template>
  <div class="intro-x text-red text-xs mt-1" v-if="isInvalid">
    {{ isInvalid.replace(`${name}:`, '') }}
  </div>
</template>

<script>
export default {
  props: ['invalidFields', 'name'],
  computed: {
    isInvalid() {
      return this.invalidFields.find(field => field.startsWith(`${this.name}:`))
    }
  }
}
</script>

<style>

</style>